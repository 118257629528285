<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">{{ translations.tcVisitNoteDetails }}</h1>
    </page-header>

    <page-body>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-3">{{ translations.tcVisitNoteDetails }}</h1>
        <div class="d-flex mb-2">
          <i class="mr-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="29" viewBox="0 0 18 29">
              <path
                fill="#003946"
                d="M19.4133553,126.182446 L13.0210995,139.828758 C12.8020299,140.288119 12.1317422,141.613705 11.6249393,142.617737 C11.5052697,142.852379 11.2647285,143 11.0020622,143 C10.7393958,143 10.4988547,142.852379 10.3791851,142.617737 C9.87238217,141.613705 9.20863387,140.288119 8.99937332,139.828758 L2.58422961,126.182446 C2.36521091,125.704643 2.21229977,125.199041 2.12974186,124.679678 C1.24692392,119.479052 4.97110775,114.485138 10.2058912,114.03562 C12.7204458,113.810842 15.2133359,114.657196 17.0753738,116.367861 C18.9374118,118.078525 19.997056,120.495902 19.9953612,123.029258 C20.0336111,124.110394 19.8349351,125.18678 19.4133553,126.182446 Z M11.7622665,118.580016 C9.83085988,118.246591 7.90655963,119.206127 7.00487413,120.95225 C6.10318862,122.698374 6.43157222,124.829346 7.81672751,126.220592 C9.20188279,127.611838 11.3251216,127.943269 13.0659499,127.039979 C14.8067782,126.136689 15.7646769,124.206501 15.4341352,122.268031 C15.1107327,120.383063 13.6403645,118.906229 11.7622665,118.580016 L11.7622665,118.580016 Z"
                transform="translate(-2 -114)"
              />
            </svg>
          </i>
          <h6>{{ location.org_name }}</h6>
        </div>
        <div class="mb-3 address">
          {{ location.address_line_1 }}
          <br v-if="location.address_line_1" />
          {{ location.address_line_2 }}
          <br v-if="location.address_line_2" />
          {{ location.city }}
          <span v-if="location.city && location.state">, </span>{{ location.state }} {{ location.zip }}
          <br />
          {{ location.phone_number_formatted }}
          <span v-if="location.email && location.phone_number_formatted">|</span>
          <a :href="'mailto:' + location.email">{{ location.email }}</a>
        </div>
        <div class="d-flex mb-3">
          <i class="mr-3">
            <i-calendar/>
          </i>
          <p>{{ formattedDate }}</p>
        </div>
        <section class="section-4 bg-block mb-4">
          <div class="body" v-if="Section3">
            <div class="presentation-details">
              <div class="font-weight-bold">
                <span class="label">{{ translations.tcContactedBy }}:</span>
                <span class="value">{{ userSelectedNote.name }}</span>
              </div>
              <div class="font-weight-bold">
                <span class="label"></span>
                <span class="value"></span>
              </div>
              <div class="font-weight-bold">
                <span class="label">{{ translations.tcNoteType }}:</span>
                <span class="value">{{ visitType }}</span>
              </div>
            </div>
          </div>
        </section>
        <section class="section-4 bg-block mb-4">
          <div class="body">
            <div class="presentation-details">
              <div class="font-weight-bold">
                <span class="label">{{ translations.tcNotes }}:</span>
                <span class="value keep-breaks">{{ userSelectedNote.nte_note }}</span>
              </div>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-5">
          <div class="body">
            <div class="d-flex action-buttons">
              <b-button
                variant="primary"
                v-if="iCanSeeAny(secured_note_edit_controls)"
                @click="handleEditButtonClick"
                class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0"
                >{{ translations.tcEdit }}</b-button
              >
              <b-button variant="tertiary" @click="handleCancelButtonClick" class="flex-0 mr-0 mr-sm-3 w-100-sd">{{
                translations.tcCancel
              }}</b-button>
              <b-button
                variant="secondary"
                v-if="iCanSeeAny(secured_note_delete_controls)"
                @click="handleDeleteButtonClick"
                class="flex-0 mr-0 mr-sm-3 w-100-sd"
                >{{ translations.tcDelete }}</b-button
              >
            </div>
          </div>
        </section>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import date_data from '@/json/date.json'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'card_facility_note_details',
  mixins: [translationMixin],
  data() {
    return {
      formattedDate: '',
      translations: {},
      toggle: true,
      Section3: true,
      secured_note_delete_controls: {
        delete_scripture_placement_facility_notes_button: 'ca8dae12-66fa-45f2-b329-1b79c661f577',
        delete_aux_facility_note_button: '24e805f5-106f-49e7-8ec3-087260fe09b6',
      },
      secured_note_edit_controls: {
        edit_scripture_placement_facility_notes_button: '5d19b1a1-9c43-4738-83d4-278ce9edf663',
        edit_aux_facility_note_button: 'fbd3819d-d374-44de-917c-2d80663af107',
      },
      visitType: '',
    }
  },
  methods: {
    ...mapActions({
      saveProspectNote: 'membership/saveProspectNote',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedNote: 'user/setSelectedNote',
    }),
    async handleEditButtonClick() {
      this.$router.push({ name: 'card_facility_edit_note' })
    },
    async handleCancelButtonClick() {
      await this.setSelectedNote({})
      this.$router.push('/programs/gc/profile/card_notes')
    },
    async handleDeleteButtonClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: this.translations.tcCancel,
        confirmButtonText: this.translations.tcOk,
        text: this.translations.tcDeleteNoteConfirmationMessage,
      }).then((result) => {
        if (result.value) {
          let note = {
            nte_entity_key: this.userSelectedFacilityKey,
            nte_contact_by_ind_key: this.userSelectedNote.nte_contact_by_ind_key,
            nte_note: this.userSelectedNote.nte_note,
            nte_contact_date: this.userSelectedNote.contact_date,
            nte_ctt_key: this.userSelectedNote.nte_ctt_key,
            nte_add_user: this.userId,
            nte_delete_flag: true,
            nte_key: this.userSelectedNote.nte_key,
            // Subject is hard coded to match the old version of theConnection
            nte_subject: 'Church Contact Notes',
          }
          this.saveNote(note)
        }
      })
    },
    async saveNote(note) {
      let result = true
      await Promise.all([this.setLoadingStatus(true), (result = await this.saveProspectNote(note))]).then(() => {
        this.setLoadingStatus(false)
        this.$swal({
          icon: result ? 'success' : 'error',
          confirmButtonText: this.translations.tcOk,
          text: result
            ? this.translations.tcTheNoteWasDeleted
            : this.translations.tcTheNoteWasNotDeleted,
        }).then((result) => {
          if (result.value) {
            this.setSelectedNote({})
            this.$router.push({ name: 'card_notes' })
          }
        })
      })
    },
    updateDynamicText() {
      if (!this.translations.common || !this.userSelectedNote) return false

      this.formattedDate = this.returnFormattedDate(this.userSelectedNote.contact_date)
      this.visitType = this.textLookup(this.userSelectedNote.contact_type, 'placement-facility-note-types', 'commonText')
    },
  },
  async created() {
    if (!this.userSelectedFacilityKey || !this.userSelectedNote) {
      this.$router.push({ name: 'gideon-card' })
    }

    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations('buttons', 'forms'),
        this.getComponentTranslations('common.placement-facility-note-types')
      ]).then(results => {
        this.stripReadableText(results[1], 'text')
        this.updateDynamicText()
      })
    } catch (e) {
      console.error('Error in GideonCardNoteDetails, created()', e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      location: 'card/getFacilitySummary',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
      userSelectedNote: 'user/userSelectedNote',
    }),
    displayDate() {
      let cDate = !!this.userSelectedNote.contact_date ? new Date(this.userSelectedNote.contact_date) : null
      let month = !!cDate ? this.months[cDate.getMonth()] : ''
      let dayOfWeek = !!cDate ? this.days[cDate.getDay()] : ''
      let day = !!cDate ? cDate.getDate() : ''
      let year = !!cDate ? cDate.getFullYear() : ''
      const newDisplayDate = `${dayOfWeek} ${month} ${day} ${year}`

      return newDisplayDate
    },
    months() {
      return date_data.months.map((dm) => dm.text)
    },
    days() {
      return date_data.days.map((dy) => dy.text)
    },
  },
  components: {
    iCalendar: iCalendar,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.keep-breaks {
  white-space: pre-wrap;
}

h3 {
  line-height: 1;
}

.page-body {
  padding: 80px 15px;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-1 {
  .d-flex {
    align-items: center;
  }
  i {
    width: 22px;
    font-size: 0;
    text-align: center;
    svg {
      display: inline-block;
    }
  }
  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .address {
    padding-left: 36px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.presentation-specs,
.presentation-details {
  max-width: 680px;
  column-gap: 40px;
  margin-bottom: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #979797;
  @include breakpoint(sm) {
    columns: 1;
    column-gap: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  div {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    span {
      display: block;
      color: #636363;
      font-weight: 700;
      line-height: 22px;
    }
    .label {
      display: block;
      margin-right: 0.25rem;
      text-transform: uppercase;
    }
  }
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: middle;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    button {
      min-width: 280px;
    }

    &.action-buttons {
      button {
        min-width: inherit;
      }
    }
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
